// const tag2seconds = (tag: any) => {
//   const mm = Number.parseInt(tag[1], 10);
//   const ss = Number.parseFloat(tag[2].replace(":", "."));
//   return mm * 60 + ss - 0.25;
// };

export const download = (
  content: any,
  filename: string,
  mimeType: string = "text/plain",
) => {
  const a = document.createElement("a"); // Create "a" element
  const blob = new Blob([content], { type: mimeType }); // Create a blob (file-like object)
  const url = URL.createObjectURL(blob); // Create an object URL from blob
  a.setAttribute("href", url); // Set "a" element link
  a.setAttribute("download", filename); // Set download filename
  a.click(); // Start downloading
};
