import { FC, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import {
  parseSrt,
  SrtLyrics,
  srtLyrics2SrtFile,
} from "../utils/srtParser";
// import SrtVisualizer from "./SrtVisualizer";
import { download } from "../utils/utils";

type SrtDownloadProps = {
  srt: SrtLyrics;
  audioUrl: string;
};

const Tips = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const SrtPlayerContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SrtDownloadContainer = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  height: 140px;
  flex-wrap: wrap;
  justify-content: center;
  -webkit-transition: color 50ms linear;
  -ms-transition: color 50ms linear;
  transition: color 50ms linear;
  padding: 10px;
  width: 180px;
`;

const SrtDownload = styled.div<SrtDownloadProps>`
  &.active {
    color: lightgreen;
  }
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex-wrap: wrap;
  justify-content: left;
  -webkit-transition: color 50ms linear;
  -ms-transition: color 50ms linear;
  transition: color 50ms linear;
  width: fit-content;
  align-items: center;
`;

const SrtDownloadReset = styled.div<SrtDownloadProps>`
  margin-left: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: right;
  -webkit-transition: color 50ms linear;
  -ms-transition: color 50ms linear;
  transition: color 50ms linear;
  align-items: center;
`;

export const SrtPlayer: FC = () => {
  const [srt, setSrt] = useState<SrtLyrics>();
  const [audioUrl, setAudioUrl] = useState<string>();
  const [srtFileName, setSrtFileName] = useState<string>();
  const audioRef = useRef<HTMLAudioElement>();
  const onDrop = useCallback((files: File[]) => {
    files.forEach((item) => {
      if (item.type.startsWith("audio/")) {
        const urlObj = URL.createObjectURL(item);
        setAudioUrl(urlObj);
      } else if (item.name.endsWith(".srt")) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const raw = (event.target as any).result;
          setSrt(parseSrt(raw));
          setSrtFileName(item.name);
          // console.debug(`VV srtLyricsTimestamp VV\n`, srtLyricsTimestamp)
        };
        reader.readAsText(item);
      }
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // noClick: Boolean(srt && audioUrl),
    // noKeyboard: Boolean(srt && audioUrl),
    noClick: Boolean(srt),
    noKeyboard: Boolean(srt),
  });

  return (
    <SrtPlayerContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {/*{Boolean(srt && audioUrl) ? (*/}
      {Boolean(srt) ? (
        // <SrtVisualizer srt={srt} audioRef={audioRef} wordLevel />
        <span id={"srt-filename"}>
          <h2>{`<Selected file>`}</h2>
          <h4>{`\t→\t${srtFileName}`}</h4>
        </span>
      ) : (
        <Tips>
          <p>Tips:</p>
          <ol id={"tip-list"}>
            <li>Drag n drop your lyrics (.srt)</li>
            <li>Download :)</li>
          </ol>
        </Tips>
      )}
      {Boolean(audioUrl) && (
        <audio
          ref={audioRef}
          controls
          src={audioUrl}
          autoPlay={Boolean(audioUrl && srt)}
        />
      )}
      {Boolean(srt) ? (
        <SrtDownloadContainer>
          <SrtDownload
            srt={srt}
            audioUrl={audioUrl}
            onClick={() => {
              download(
                srtLyrics2SrtFile(srt),
                srtFileName.replaceAll(".srt", "_word.srt"),
              );
            }}
          >
            <img
              id={"download-icon"}
              alt={"download-icon"}
              src={"/download.png"}
              height={"60px"}
              width={"60px"}
              onClick={() => {
                download(
                  srtLyrics2SrtFile(srt),
                  srtFileName.replaceAll(".srt", "_word.srt"),
                );
              }}
            />
            {"Download"}
          </SrtDownload>
          <SrtDownloadReset srt={srt} audioUrl={audioUrl}>
            <img
              id={"reset-icon"}
              alt={"refresh-icon"}
              src={"/flat_restart_icon.svg"}
              width={"60px"}
              height={"60px"}
              onClick={() => {
                setSrt(null);
              }}
            />
            {"Reset"}
          </SrtDownloadReset>
        </SrtDownloadContainer>
      ) : (
        <span></span>
      )}
    </SrtPlayerContainer>
  );
};

export default SrtPlayer;
