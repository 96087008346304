import Decimal from "decimal.js";
import { Buffer } from "buffer";

export type SrtLyricWord = {
  timeStart: number;
  timeEnd: number;
  text: string;
};

export type SrtLyricLine = {
  timeStart: number;
  timeEnd: number;
  words: SrtLyricWord[];
  numWords: number;
};
export type SrtLyricLineDecimal = {
  timeStart: Decimal;
  timeEnd: Decimal;
  words: SrtLyricWord[];
  numWords: number;
};

export type SrtLyrics = {
  lines: SrtLyricLine[];
  numLines: number;
};

export type SrtLyricsTimestamps = {
  hour: {
    start: Decimal;
    end: Decimal;
  };
  minute: {
    start: Decimal;
    end: Decimal;
  };
  second: {
    start: Decimal;
    end: Decimal;
  };
  milliseconds: {
    start: Decimal;
    end: Decimal;
  };
  text: string;
};

export type SrtLyricsTimestamp = {
  hour: Decimal;
  minute: Decimal;
  second: Decimal;
  milliseconds: Decimal;
  text: string;
};

export type NewLine = {
  windows: string;
  linux_n_unix: string;
};
// MARK: Windows new Line
const newLine: NewLine = {
  windows: "\r\n",
  linux_n_unix: "\n",
};

const DecimalTo3 = (decimal: Decimal): number => {
  return Number(decimal.toFixed(3));
};

const srtLyricsTimestamp2SrtLyricLines = (
  slt: SrtLyricsTimestamps,
): SrtLyricLineDecimal => {
  let timeStart = new Decimal(0);
  let timeEnd = new Decimal(0);

  timeStart = timeStart.add(slt.hour.start.mul(60).mul(60));
  timeStart = timeStart.add(slt.minute.start.mul(60));
  timeStart = timeStart.add(slt.second.start);
  timeStart = timeStart.add(slt.milliseconds.start.div(1000));

  timeEnd = timeEnd.add(slt.hour.end.mul(60).mul(60));
  timeEnd = timeEnd.add(slt.minute.start.mul(60));
  timeEnd = timeEnd.add(slt.second.end);
  timeEnd = timeEnd.add(slt.milliseconds.end.div(1000));

  return {
    timeStart: timeStart,
    timeEnd: timeEnd,
    words: [] as SrtLyricWord[],
    numWords: 0,
  } as SrtLyricLineDecimal;
};

const timestampTag2secondsSrt = (
  timestampTag: RegExpExecArray,
): SrtLyricsTimestamps => {
  if (!timestampTag || timestampTag.length < 8) {
    return null;
  }

  let startIndex: number = 0;
  if (!Decimal.isDecimal(timestampTag[startIndex])) {
    startIndex = startIndex + 1;
  }

  // console.debug(`timestampTag =>\n`, timestampTag);

  const hhStart = new Decimal(timestampTag[startIndex]);
  const mmStart = new Decimal(timestampTag[startIndex + 1]);
  const ssStart = new Decimal(timestampTag[startIndex + 2]);
  const milliStart = new Decimal(timestampTag[startIndex + 3]);

  const hhEnd = new Decimal(timestampTag[startIndex + 4]);
  const mmEnd = new Decimal(timestampTag[startIndex + 5]);
  const ssEnd = new Decimal(timestampTag[startIndex + 6]);
  const milliEnd = new Decimal(timestampTag[startIndex + 7]);

  return {
    hour: {
      start: hhStart,
      end: hhEnd,
    },
    minute: {
      start: mmStart,
      end: mmEnd,
    },
    second: {
      start: ssStart,
      end: ssEnd,
    },
    milliseconds: {
      start: milliStart,
      end: milliEnd,
    },
    text: "",
  } as SrtLyricsTimestamps;
};

const wordTag = (raw: string): string[] => {
  const strs: string[] = [];

  for (let i = 0; i < raw.length; i++) {
    const char = raw.charAt(i);
    strs.push(char);
  }

  return strs;
};

const SplitWordFromSrtLyricLineDecimal = (
  slld: SrtLyricLineDecimal,
  text: string,
): SrtLyricWord[] => {
  const srtlw: SrtLyricWord[] = [];
  const chars: string[] = wordTag(text);
  const charsLength = chars.length;

  const currLineStart: Decimal = slld.timeStart;
  const currLineEnd: Decimal = slld.timeEnd;
  const currLineGap: Decimal = currLineEnd.sub(currLineStart).div(charsLength);
  // console.debug(`text: '${text}', start: ${slld.timeStart}, end: ${slld.timeEnd}, gap: ${currLineGap.toNumber()}`);

  for (let i = 0; i < charsLength; i++) {
    const char = chars[i];
    let startDec: Decimal = new Decimal(0);
    let endDec: Decimal = new Decimal(0);
    startDec = currLineStart.add(currLineGap.mul(i));
    endDec = currLineStart.add(currLineGap.mul(i + 1));
    // MARK: Exception handling for very start and very end.
    if (i === 0) {
      startDec = currLineStart;
    }
    if (i === chars.length - 1) {
      endDec = currLineEnd;
    }

    const slw: SrtLyricWord = {
      timeStart: DecimalTo3(startDec),
      timeEnd: DecimalTo3(endDec),
      text: char,
    } as SrtLyricWord;

    srtlw.push(slw);
  }

  return srtlw;
};

//export const parseSrt = (srtString: string): SrtLyrics => {
export const parseSrt = (srtString: string): SrtLyrics => {
  const lines = srtString.split(/\r\n|\n|\r/g);

  const subNum = /^(\d+)$/;
  const subTimeTag = /^(\d+):(\d+):(\d+),(\d+) --> (\d+):(\d+):(\d+),(\d+)$/s;
  // const subContext = /^(\p{L}\n|\r|\r\n)+$/gu;
  const subNewLine = /^(\n|\r|\r\n)$/;

  let srtTimestamps: SrtLyricsTimestamps[] = [];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    // MARK: check sub text number
    if (subNum.exec(line) == null) {
      continue;
    }
    // MARK: To reach here, it must pass sub text number check.
    // MARK: To check next line. - Timestamp
    i++;
    const lineTimeTag = lines[i];
    const timeLine = subTimeTag.exec(lineTimeTag)!;
    if (timeLine == null) {
      const errText: string = `Line No.${i + 1}, failed to read subTimeTag. timeLine='null', raw text => '${lineTimeTag}'`;
      console.error(errText);
      throw errText;
    }
    // MARK: Checking the required timestamp format is captured or not.
    if (timeLine.length && timeLine.length < 8) {
      const errText: string = `Failed to catch Timestamp format of .srt file. timeLine=> ${timeLine.toString()}`;
      console.error(errText);
      throw errText;
    }

    let currSub: SrtLyricsTimestamps = timestampTag2secondsSrt(timeLine);

    // srtTimestamps.push();
    // MARK: To check next line. - Texts
    i++;
    // MARK: appends texts until empty line or next lineNumber comes out.

    // // MARK: Safety for null point error or array out of rage.
    // if (i + 1 === lines.length) {
    //   break;
    // }
    // if (i + 2 === lines.length) {
    //   const lastLine = lines[i + 1];
    //   if (subNewLine.exec(lastLine) == null || lastLine === "") {
    //     break;
    //   }
    // }
    let texts: string[] = [];
    for (let j = i; j < lines.length; j++) {
      // MARK: Safety for null point error or array out of rage.
      // if (j + 2 === lines.length) {
      //   const lastLine = lines[j + 1];
      //   if (subNewLine.exec(lastLine) || lastLine === "") {
      //     break;
      //   }
      // }

      // MARK: Keep Parse Text
      const line: string = lines[j];
      const nextLine: string = lines[j + 1];
      const nextOfNextLine = lines[j + 2];
      const resSubNewLine = subNewLine.exec(nextLine);
      const resSubNum = subNum.exec(nextOfNextLine);
      // console.debug(
      //   `line: ${line}, nextLine: ${nextLine}, resSubNewLine: ${resSubNewLine}, resSubNum: ${resSubNum}`,
      // );

      texts.push(line);

      if (!nextLine && resSubNum != null) {
        // MARK: Go to next line
        i = j - 1;
        break;
      }
      if (!nextLine && resSubNewLine == null && resSubNum == null) {
        break;
      }
    }

    // console.debug(`current text VV \n${text}`);

    // MARK: save extracted text on currSub.
    currSub.text = texts.join("\n");
    // MARK: Finally save on 'by line' Level
    srtTimestamps.push(currSub);
  }

  // MARK: Save as SrtPlayer format
  const srtLines: SrtLyricLine[] = [];
  for (let i = 0; i < srtTimestamps.length; i++) {
    const srtTimestamp: SrtLyricsTimestamps = srtTimestamps[i];
    const currSrtLyricLine: SrtLyricLineDecimal =
      srtLyricsTimestamp2SrtLyricLines(srtTimestamp);
    const currWords: SrtLyricWord[] = SplitWordFromSrtLyricLineDecimal(
      currSrtLyricLine,
      srtTimestamp.text,
    );
    // console.debug(currWords);

    const curr: SrtLyricLine = {
      timeStart: DecimalTo3(currSrtLyricLine.timeStart),
      timeEnd: DecimalTo3(currSrtLyricLine.timeEnd),
      words: currWords,
      numWords: currWords.length,
    } as SrtLyricLine;

    // MARK: Save split words and lines.
    srtLines.push(curr);
  }

  // console.debug("srtParser.ts - parseSrt(): srtLines =>", srtLines);
  // console.debug("srtParser.ts - parseSrt(): numLines => ", srtLines.length);

  // MARK: Return SrtLyrics
  return {
    lines: srtLines,
    numLines: srtLines.length,
  } as SrtLyrics;
};

const newLineBuffer: Buffer = Buffer.from(newLine.linux_n_unix, "utf-8");

export const timestamp2SrtLyricsTimestamp = (
  timestamp: Decimal | number,
  text: string = "",
): SrtLyricsTimestamp => {
  if (typeof timestamp === "number") {
    timestamp = new Decimal(timestamp);
  }

  // MARK: 01 - Extract integer part for split milliseconds part.
  const tsInt: Decimal = timestamp.toDecimalPlaces(0, Decimal.ROUND_DOWN);
  const tsHH: Decimal = tsInt
    .div(60)
    .div(60)
    .toDecimalPlaces(0, Decimal.ROUND_DOWN);
  const tsMM: Decimal = tsInt
    .sub(tsHH.mul(60).mul(60))
    .div(60)
    .toDecimalPlaces(0, Decimal.ROUND_DOWN);
  const tsSS: Decimal = tsInt
    .sub(tsHH.mul(60).mul(60))
    .sub(tsMM.mul(60))
    .div(60)
    .toDecimalPlaces(0, Decimal.ROUND_DOWN);
  const tsMilli: Decimal = timestamp.sub(tsInt).mul(1000);

  return {
    hour: tsHH,
    minute: tsMM,
    second: tsSS,
    milliseconds: tsMilli,
    text,
  } as SrtLyricsTimestamp;
};

export const srtLyricsTimestamp2SrtFormat = (
  slt: SrtLyricsTimestamp,
): string => {
  return `${slt.hour.toString().padStart(2, "0")}:${slt.minute.toString().padStart(2, "0")}:${slt.second.toString().padStart(2, "0")},${slt.milliseconds.toString().padStart(3, "0")}`;
};

export const srtLyrics2SrtFile = (srtLyrics: SrtLyrics): Blob => {
  const srtTexts: Buffer[] = [];

  for (let i = 0; i < srtLyrics.lines.length; ++i) {
    const line = srtLyrics.lines[i];

    for (let j = 0; j < line.words.length; j++) {
      const word = line.words[j];

      // MARK: Write the current word's number. (=> sub number)
      const subNum: Buffer = Buffer.from(`${srtTexts.length + 1}`, "utf-8");
      // MARK: Write the current word's timestamp (=> sub timestamp)
      const subTsStart: Buffer = Buffer.from(
        srtLyricsTimestamp2SrtFormat(
          timestamp2SrtLyricsTimestamp(word.timeStart),
        ),
        "utf-8",
      );
      const subTsEnd: Buffer = Buffer.from(
        srtLyricsTimestamp2SrtFormat(
          timestamp2SrtLyricsTimestamp(word.timeEnd),
        ),
        "utf-8",
      );
      const subTimestamp: Buffer = Buffer.from(
        `${subTsStart} --> ${subTsEnd}`,
        "utf-8",
      );
      // MARK: Write the current word's text (=> sub text)
      const subText: Buffer = Buffer.from(word.text, "utf-8");

      // MARK: Push strings to make blob
      srtTexts.push(subNum);
      srtTexts.push(newLineBuffer);
      srtTexts.push(subTimestamp);
      srtTexts.push(newLineBuffer);
      srtTexts.push(subText);
      srtTexts.push(newLineBuffer);
      if (j + 1 < line.words.length || i + 1 < srtLyrics.lines.length) {
        srtTexts.push(Buffer.from(newLine.linux_n_unix, "utf-8"));
      }
    }
  }

  const srtText: Buffer = Buffer.concat(srtTexts);

  return new Blob([srtText], { type: "text/plain" });
};
